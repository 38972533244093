<template>
    <div id="pageTable"> 
        <v-breadcrumbs :items="itemsLinks">
            <template v-slot:divider>
                <v-icon>chevron_right</v-icon>
            </template>
        </v-breadcrumbs>

        <v-overlay :value="loadingLottie" :z-index="999">
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    >
                    </v-img>
                </v-progress-circular>
            </div>
        </v-overlay>

        <v-container grid-list-xl fluid>
            <v-row>
                <v-col cols="12" lg="12" class="ml-2">
                    <simple-table
                        ref="tableDocumentos"
                        :tableName="'Documentos'"
                        :columns="columns"
                        :rows="itemsDocumentos"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="false"
                        :loading="isLoadingTable"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div 
                                        v-if="item.idEntregado == null"
                                        class="tblPrincipal"
                                        style="color: red;"
                                    >
                                        Pendiente
                                    </div>
                                    <div
                                        v-else
                                    >
                                        Entregado
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <template v-if="item.url != null">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-show="tipoDoc(item.url)"
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        :class="tipoDocCSS(item.url)"
                                                        icon
                                                        fab
                                                        small
                                                        dark
                                                        @click="abrirModalPDF(item)"
                                                    >
                                                        <v-icon class="tamIconBoton iconoDelgadoBoton material-icons-outlined">
                                                            remove_red_eye
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vista Previa</span>
                                            </v-tooltip>
                                            
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon 
                                                        fab 
                                                        small 
                                                        dark 
                                                        @click="abrirModal('update', item)"
                                                    >
                                                        <v-icon class="tamIconoBoton iconoDelgadoBoton">
                                                            autorenew
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Actualizar</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        small
                                                        @click="descargar(item)"
                                                    >
                                                        <v-icon class="tamIconoBoton iconoDelgadoBoton">
                                                            arrow_downward
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        small
                                                        dark
                                                        @click="eliminar(item.idEntregado, item.nombre)"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">
                                                            delete
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-else>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        @click="abrirModal('update', item)"
                                                        small
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">
                                                            cloud_upload
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Subir documento</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>    
                    </simple-table>
                </v-col>
            </v-row>
        </v-container>


        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="card_titulo padding_izquierdo">
                            <div class="mb-5">
                                <h2 class="titleModal">{{ titulo_modal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                                        <Upload
                                            :tamanioArchivo="10485760" 
                                            :multiple="false"
                                            :extensionInput="'.pdf,.jpg,.jpeg,.png,.zip,.rar,xls,.xlsx,.doc,.docx,.pptx'"
                                            :extensionDrop="'.pdf|.jpg|.jpeg|.png|.zip|.rar|.xls|.xlsx|.doc|.docx|.pptx'"
                                            :arrayFile="archivo.archivo"
                                            @vModelFile="(data)=> archivo.archivo = data"
                                        >
                                        </Upload>
                                    </v-col>
                                    <v-col class="d-flex justify-end pb-0">
                                        <p class="text-file">
                                            Max: 10MB (Megabytes)
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()"
                                :loading="loading_guardar"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()"
                                :loading="loading_guardar"
                            >
                                Guardar
                            </v-btn>      
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>

        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog_pdf" persistent max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalPDF"></v-btn>
                    </template>
                    <v-card>
                        <div class="d-flex justify-end">
                            <v-btn
                                @click="cerrarModal()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar mr-3 mt-3"
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        </div>
                        <v-card-title class="pt-0">
                            <span class="titleModal ml-2" v-text="titulo_modal"></span>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                            <v-container grid-list-md>
                                <v-row>
                                    <template v-if="!procesoFinalizado" >
                                        <v-col cols="12" xs="12" sm="12" md="12" class="align-center mb-10" align="center" justify="center">
                                            <h3 class="tituloCodigo">Procesando</h3>
                                            <beat-loader
                                                :loading="true"
                                                :color="'#004BAF'"
                                                :margin="'2px'"
                                                :radius="'100px'"
                                            ></beat-loader>
                                        </v-col>
                                        
                                    </template>
                                    <template>
                                        <v-col v-show="procesoFinalizado" cols="12"  xs="12" sm="12" md="12">
                                            <iframe src="" id="myIframe"  width='100%' height='400px' allowfullscreen webkitallowfullscreen></iframe>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>
    </div>
</template>

<script>
import DataTableSimple from "@/components/simpletable/Datatable"
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import Upload from '@/components/DragAndDrop.vue';
import documentoApi from "@/api/documentos.js";
import Notify from '@/plugins/notify';

export default {
    name:"DocumentosEmpleado",
    components: {
        'simple-table': DataTableSimple,
        BeatLoader,
        Upload
    },
    data() {
        return {
            url: "documentos/faltantes",
            columns: [
                { label: "Nombre", name: "nombre", filterable: false },
                { label: "Estatus", name: "estatus", filterable: false },
                { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
            ],
            filters: {
                empleado_id: null,
            },
            loading_guardar: false,
            archivo: {
                archivo         : [],
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                empleado_id     : null,
                documento_id    : null,
            },
            documento: {
                id          : 0,
                nombre      : "",
                descripcion : "",
                tipo_id     : 0,
                empleado_id : 0,
                extension   : "",
                url         : null,
            },
            dialog          : false,
            titulo_modal    : "",
            itemsLinks      : [
                {
                    text: "Empleados",
                    disabled: false,
                    to: "/empleado/empleados",
                },
                {
                    text: "Documentos",
                    disabled: true,
                    to: "/documentos",
                },
            ],
            datosLogin          : "",
            rol                 : "",
            loading             : true,
            accion              : "",
            tipos               : [],
            entregados          : [],
            conteo_parametros   : 0,
            descripcion         : "",
            title               : "Image Upload",
            nombre_archivo      : "",
            imageUrl            : "",
            empleado            : null,
            dialog_pdf          : false,
            datos               : null,
            urlDoc              : null,
            procesoFinalizado   : false,
            loadingLottie       : false,
            fraseLoading        : 'Descargando',
            itemsDocumentos     : [],
            isLoadingTable      : false,
        }
    },
    methods: {
        getDocumentos(){
            let param = {
                empleado_id : this.empleado.id
            };
            this.isLoadingTable = true;
            documentoApi.getDocumentosFaltantes(param).then((response)=>{
                this.isLoadingTable = false;
                this.itemsDocumentos = response.data;
            })
            .catch(err => {
                this.isLoadingTable = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                console.log(err);
            });
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if (!this.$session.has("documentos")) {
                this.$router.push("/empleados");
            } else {
                this.loading = true;
                this.filters.empleado_id = this.empleado.id;
                this.getDocumentos();
            }
        },
        eliminar(id, nombre) {
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el documento?",
                "Eliminar",
                () => {
                    self.loading = true;
                    documentoApi.deleteDocumento(id).then((response) => {
                        self.respuesta_ok("Documento eliminado");
                    })
                    .catch((err) => {
                        console.log(err);
                        Notify.ErrorToast(err.response.data.error);
                    });
                }
            );
        },
        async descargar(item = null) {
            let self =  this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "incidencias/download";
            var nombre_archivo = item.url.split("/");
            nombre_archivo = item.nombre + "_" + nombre_archivo[3];
            let parametros = { url_archivo: item.url};
            this.loadingLottie = true;
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombre_archivo);
                    self.loadingLottie = false;
                }).catch((err) => {
                    self.loadingLottie = false;
                    Notify.ErrorToast('No se puedo descargar el archivo.');
                });
            } catch (error) {
                self.loadingLottie = false;
                Notify.ErrorToast('No se puedo descargar el archivo.');
                console.log(error);
            }
        },
        abrirModal(accion, item = null) {
            this.descripcion = item.descripcion;
            this.accion = accion;
            this.titulo_modal = item.nombre;
            (this.archivo = {
                archivo: [],
                cliente_id: this.empleado.cliente_id,
                empresa_id: this.empleado.empresa_id,
                sucursal_id: this.empleado.sucursal_id,
                departamento_id: this.empleado.departamento_id,
                empleado_id: this.empleado.id,
            }),
                (this.documento = {
                descripcion: item.nombre,
                tipo_id: item.id,
                empleado_id: this.empleado.id,
                });
            if (item.idEntregado == undefined) {
                this.accion = "add";
            } else {
                this.documento.id = item.idEntregado;
                this.archivo.documento_id = item.idEntregado;
            }
            setTimeout(function() {
                document.getElementById("btnModal").click();
            }, 100);
        },
        abrirModalPDF(item){
            self = this;
            this.titulo_modal = "Vista previa";
            const axios = require("axios");
            let url = "incidencias/download";
            let parametros = { url_archivo: item.url };
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    var fileURL = URL.createObjectURL(response.data);
                    $('#myIframe').attr('src', fileURL); 
                    $('iframe').on("load", function () {
                        $(this).contents().find('img').css({width: '100%'});
                    });
                    self.procesoFinalizado  = true;
                }).catch((err) => {
                    Notify.ErrorToast('No se puede mostrar el archivo.');
                });
            } catch (error) {
                console.log(error);
            }
            setTimeout(function() {
                document.getElementById("btnModalPDF").click();
            }, 100);
        },
        tipoDoc(url=null){
            if(url.endsWith('.jpg') || url.endsWith('.pdf') || url.endsWith('.png') || url.endsWith('.jpeg')){
                return true;
            }
            else{
                return false;
            } 
        },
        tipoDocCSS(url=null){
            if(url.endsWith('.jpg') || url.endsWith('.pdf') || url.endsWith('.png') || url.endsWith('.jpeg')){
                return "alinear-btn";
            }
            else{
                return "";
            } 
        },
        guardar() {
            let self = this;
            if (this.archivo.archivo.length == 0) {
                Notify.Warning("ADVERTENCIA", "No se ha seleccinado un archivo");
                return;
            }

            this.loading_guardar = true;
            let formData = new FormData();
            formData.append("archivo", this.archivo.archivo[0]);
            formData.append("cliente_id", this.archivo.cliente_id.toString());
            formData.append("empresa_id", this.archivo.empresa_id.toString());
            formData.append("sucursal_id", this.archivo.sucursal_id ? this.archivo.sucursal_id.toString():'');
            formData.append(
                "departamento_id",
                this.archivo.departamento_id ? this.archivo.departamento_id.toString(): ''
            );
            formData.append("empleado_id", this.archivo.empleado_id.toString());

            if (this.accion === "add") {
                documentoApi.addArchivo(formData).then((response) => {
                    if (response.success) {
                        self.documento.nombre = response.nombre;
                        self.documento.extension = response.extension;
                        self.documento.url = response.url;

                        documentoApi.addDocumento(self.documento).then((response) => {
                            self.listar();
                            self.respuesta_ok("El archivo se guardó satisfactoriamente.");
                        })
                        .catch((err) => {
                            self.respuesta_error(err);
                        });
                    } 
                    else {
                        Notify.ErrorToast(response.message);
                    }
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
            } else if (this.accion === "update") {
                formData.append("documento_id", this.archivo.documento_id.toString());
                documentoApi.updateArchivo(formData).then((response) => {
                    if (response.success) {
                        self.documento.nombre = response.nombre;
                        self.documento.extension = response.extension;
                        self.documento.url = response.url;

                        documentoApi.updateDocumento(self.documento).then((response) => {
                            self.listar();
                            self.respuesta_ok("El archivo se actualizó satisfactoriamente.");
                        })
                        .catch((err) => {
                            self.respuesta_error(err);
                        });
                    } 
                    else {
                        Notify.ErrorToast(response.message);
                    }
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
            }
        },
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.nombre_archivo = files[0].name;
                if (this.nombre_archivo.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.imageUrl = fr.result;
                this.archivo.archivo = files[0]; // this is an image file that can be sent to server...
                });
            } else {
                this.nombre_archivo = "";
                this.imageUrl = "";
            }
        },
        cerrarModal() {
            this.dialog = false;
            this.dialog_pdf = false;
            this.archivo = {
                archivo: [],
                cliente_id: null,
                empresa_id: null,
                sucursal_id: null,
                departamento_id: null,
                empleado_id: null,
            };
            this.documento = {
                nombre: "",
                descripcion: "",
                tipo_id: 0,
                empleado_id: 0,
                extension: "",
                url: null,
            };
            this.conteo_parametros = 0;
            this.nombre_archivo = "";
            this.imageUrl = "";
            this.accion = "update";
            this.loading_guardar = false;
            this.fileURL=null;
            $('#myIframe').attr('src', "");
            this.procesoFinalizado = false;
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        respuesta_ok(mensaje = "") {
            this.cerrarModal();
            Notify.Success("Operación exitosa", mensaje);
            this.listar();
            this.getDocumentos();
        },
    },
    mounted() {
        console.log("pruebas");
    },
    created() {
        this.empleado = this.$session.get("documentos");
        this.listar();
    },
}
</script>

<style scoped>
    #btnModal, #btnModalPDF{
        display: none;
    }
    .material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }  
    .alinear-btn{
        margin-left: -40px;
    }
    .text-file{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #1E2245
    }
    .v-btn-cerrar .v-btn:hover::before {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
        background-color: transparent !important;
        pointer-events: none;
    }
</style>