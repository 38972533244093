var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.itemsLinks},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("chevron_right")])]},proxy:true}])}),_c('v-overlay',{attrs:{"value":_vm.loadingLottie,"z-index":999}},[_c('div',{staticClass:"align-center justify-center text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"#2DB9D1"}},[_c('v-img',{attrs:{"src":"/static/icon/favicon.ico","width":60}})],1)],1)]),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","lg":"12"}},[_c('simple-table',{ref:"tableDocumentos",attrs:{"tableName":'Documentos',"columns":_vm.columns,"rows":_vm.itemsDocumentos,"perPage":[10, 25, 50, 100],"showAdvancedFilters":false,"loading":_vm.isLoadingTable},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.nombre))])]),_c('td',[(item.idEntregado == null)?_c('div',{staticClass:"tblPrincipal",staticStyle:{"color":"red"}},[_vm._v(" Pendiente ")]):_c('div',[_vm._v(" Entregado ")])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[(item.url != null)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tipoDoc(item.url)),expression:"tipoDoc(item.url)"}],staticClass:"botonHover",class:_vm.tipoDocCSS(item.url),attrs:{"slot":"activator","icon":"","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.abrirModalPDF(item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconBoton iconoDelgadoBoton material-icons-outlined"},[_vm._v(" remove_red_eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vista Previa")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.abrirModal('update', item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(" autorenew ")])],1)]}}],null,true)},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.descargar(item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(" arrow_downward ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.eliminar(item.idEntregado, item.nombre)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v(" cloud_upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir documento")])])]],2)])])}),0)}}])})],1)],1)],1),[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"card_titulo padding_izquierdo"},[_c('div',{staticClass:"mb-5"},[_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.titulo_modal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('v-container',{attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('Upload',{attrs:{"tamanioArchivo":10485760,"multiple":false,"extensionInput":'.pdf,.jpg,.jpeg,.png,.zip,.rar,xls,.xlsx,.doc,.docx,.pptx',"extensionDrop":'.pdf|.jpg|.jpeg|.png|.zip|.rar|.xls|.xlsx|.doc|.docx|.pptx',"arrayFile":_vm.archivo.archivo},on:{"vModelFile":function (data){ return _vm.archivo.archivo = data; }}})],1),_c('v-col',{staticClass:"d-flex justify-end pb-0"},[_c('p',{staticClass:"text-file"},[_vm._v(" Max: 10MB (Megabytes) ")])])],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"loading":_vm.loading_guardar},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"loading":_vm.loading_guardar},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModalPDF"}},on))]}}]),model:{value:(_vm.dialog_pdf),callback:function ($$v) {_vm.dialog_pdf=$$v},expression:"dialog_pdf"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"v-btn-cerrar mr-3 mt-3",attrs:{"slot":"activator","small":"","icon":"","text":""},on:{"click":function($event){return _vm.cerrarModal()}},slot:"activator"},[_c('v-icon',{staticClass:"icono-cerrar"})],1)],1),_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"titleModal ml-2",domProps:{"textContent":_vm._s(_vm.titulo_modal)}})]),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',[(!_vm.procesoFinalizado)?[_c('v-col',{staticClass:"align-center mb-10",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","align":"center","justify":"center"}},[_c('h3',{staticClass:"tituloCodigo"},[_vm._v("Procesando")]),_c('beat-loader',{attrs:{"loading":true,"color":'#004BAF',"margin":'2px',"radius":'100px'}})],1)]:_vm._e(),[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.procesoFinalizado),expression:"procesoFinalizado"}],attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('iframe',{attrs:{"src":"","id":"myIframe","width":"100%","height":"400px","allowfullscreen":"","webkitallowfullscreen":""}})])]],2)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }